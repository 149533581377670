<template>
  <div id="base-home createSearch">
    <v-container>
    <v-row>
      <v-col cols="12" md="2" sm="2"></v-col>
      <v-col cols="12" md="8" sm="8" style="text-align: justify">
        <h1 class="title1">
          About Us
        </h1>
        <v-divider inset/>
        <br>

        BAYON App is digital real estate platform, develop by BIGSOFT TECHNOLOGY PLC., and incorporate in The Kingdom of Cambodia.


        <br>
        <br>
        <h2 class="subTitle1">
          Buyers
        </h2>
        <br>

        <div class="subTitle2">
          If you are just a buyer looking to buy any properties, BAYON APP is a digital mobile app for your, just download and open it, you will surf hundred properties daily on sell, pickup any property you like, contact to property owner, via phone call, or private chat, making appointment for onside visit.
        </div>


        <br>
        <br>
        <h2 class="subTitle1">
          Property owners
        </h2>
        <br>


        <div class="subTitle2">
          If you are a property owners, there is your market place to listing your property to 1,000,000 users in Cambodia and possible views from other Asian investors, Buyer will contact you directly via phone call, or private chat.
          You might negotiable directly with the buyer not relate to BAYON APP. We just a platform to engage between you and buyers to make a deal.
        </div>


        <br>
        <br>
        <h2 class="subTitle1">
          Freelancer Agents
        </h2>
        <br>
        <div class="subTitle2">
          If you are a realty agents BAYON App is a good mobile app for you to assist you every day:<br>
          -	Promote your properties up-to-20 per month with lower cost USD10.00 per month.<br>
          -	Create your agent profile within provincial level.<br>
          -	Customer can contact you directly via Chat, or Phone Call.<br>
          -	Record your clients and send notifications when needs.<br>
          -	Making appointment to remind you on-time.<br>
          -	Help you calculate loan from financial institutions (home mortgage).<br>
          -	Access property reports.<br>
        </div>



        <br>
        <br>
        <h2 class="subTitle1">
          Real Estate Company
        </h2>
        <br>


        <div class="subTitle2">
          Because of BAYON App is digital marketplace here is the right place for you to promote your company.<br>
          -	Promote your company profile to 1,000,000 users within province level.<br>
          -	Customers can contact your company directly via phone, email, or real-time chat.<br>
          -	You can create account for your staffs, or agents.<br>
          -	Your company also can create clients information and share them information when you have new listing.<br>
          -	Making appointment<br>
          -	Access properties reports<br>
          -	Loan calculators to projection to your clients.<br>
        </div>





      </v-col>
      <v-col cols="12" md="2" sm="2"></v-col>

    </v-row>
    </v-container>
  </div>

</template>


<script>
export default {
  name: "about",
  mounted() {
    this.$jQuery("body").off();
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
  created() {


  }

};
</script>

<style>

.title1 {
  color: teal;
}

.title2 {
  color: teal;
}

.subTitle1 {
  size: 12px;
  text-align: justify !important;
}

.subTitle2 {
  size: 12px;
  text-align: justify !important;

}
</style>

